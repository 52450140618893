<article class="job-listing" *ngIf="advert" [ngClass]="{ featured: advert.featured === '1' }">
  <header class="row align-items-center">
    <div class="col-md-10">
      <h3><a [routerLink]="advert.path" [innerHtml]="advert.title" [queryParams]="advert.partnership ? { partnership: true } : {}"></a></h3>
      <p [innerHtml]="advert.address"></p>
    </div>
    <div class="col-md-2 organisation-link">
      <a [routerLink]="advert.org_path">
        <img
          defaultImage="/assets/img/defaults/default-icon.svg"
          [errorImage]="advert.org_logo"
          [lazyLoad]="webp.defaultImage(advert.org_logo)"
          [alt]="advert.org_name"
          class="lazyload"
        />
      </a>
    </div>
  </header>
  <div class="row no-gutters">
    <dl class="col row no-gutters">
      <div class="col col-md-6 d-md-none d-lg-none" *ngIf="advert.org_name">
        <dt>Organisation</dt>
        <dd><div [innerHtml]="ellipsis(advert.org_name)"></div></dd>
      </div>
      <div class="col col-md-6" *ngIf="advert.c_type">
        <dt>Contract Type</dt>
        <dd><div [innerHtml]="ellipsis(advert.c_type)"></div></dd>
      </div>
      <div class="col col-md-6" *ngIf="advert.p_type">
        <dt>Position Type</dt>
        <dd><div [innerHtml]="ellipsis(advert.p_type)"></div></dd>
      </div>
      <div class="col col-md-6">
        <dt>Salary</dt>
        <dd><div>{{ advert | salary }}</div></dd>
      </div>
      <div class="col col-md-6" *ngIf="advert.end_date">
        <dt>Closing Date</dt>
        <dd><div>{{ ellipsis(advert.end_date) }}</div></dd>
      </div>
    </dl>
    <div class="col d-flex justify-content-center flex-column job-listing-actions">
      <a
        [routerLink]="advert.path"
        [queryParams]="advert.partnership ? { partnership: true } : {}"
        class="btn btn-outline-primary btn-block text-left btn-lg">
        View <span class="sr-only" [innerHtml]="advert.title"></span>
        <svg viewBox="0 0 24 24" width="18" height="18" aria-hidden="true" class="float-right">
          <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
        </svg>
      </a>
      <button
        (click)="favourite()"
        class="btn btn-outline-primary btn-block text-left btn-lg"
        [ngClass]="{'liked': advert.user_like == '1'}">
        {{ advert.user_like == '1' ? 'Unf' : 'F' }}avourite <span class="sr-only" [innerHtml]="advert.title"></span>
        <svg aria-hidden="true" class="float-right" height="18" viewBox="0 0 24 24" width="18">
          <path *ngIf="advert.user_like == '0'" d="M12 15.422l3.75 2.25-0.984-4.266 3.328-2.906-4.406-0.375-1.688-4.031-1.688 4.031-4.406 0.375 3.328 2.906-0.984 4.266zM21.984 9.234l-5.438 4.734 1.641 7.031-6.188-3.75-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609z"></path>
          <path *ngIf="advert.user_like == '1'" d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"></path>
        </svg>
      </button>
    </div>
  </div>
</article>
