import { MjsSearchParams } from '@modules/search/models/mjs-search-params.model';
import { MjsSearchSortOptions } from '@modules/search/models/mjs-search-params.model';

const sortValues: MjsSearchSortOptions = {
  pd: { label: 'Added (New - Old)', key: 'start_date', order: 'DESC' },
  pu: { label: 'Added (Old - New)', key: 'start_date', order: 'ASC' },
  cd: { label: 'Closing date (New - Old)', key: 'end_date', order: 'DESC' },
  cu: { label: 'Closing date (Old - New)', key: 'end_date', order: 'ASC' },
  la: { label: 'Location (A-Z)', key: 'field_area', order: 'ASC' },
  ld: { label: 'Location (Z-A)', key: 'field_area', order: 'DESC' },
  oa: { label: 'Organisation (A-Z)', key: 'organisation_name', order: 'ASC' },
  od: { label: 'Organisation (Z-A)', key: 'organisation_name', order: 'DESC' },
  ta: { label: 'Job Title (A-Z)', key: 'job_advert_title', order: 'ASC' },
  td: { label: 'Job Title (Z-A)', key: 'job_advert_title', order: 'DESC' }
};

const distanceValues = [5, 10, 20, 50, 100];

const searchParams: MjsSearchParams = {
  org: {
    type: 'numberArray',
    value: [],
    arrayType: '+'
  },
  parent_org: {
    type: 'numberArray',
    value: [],
    arrayType: '+'
  },
  parent_cat: {
    type: 'numberArray',
    value: [],
    arrayType: '+'
  },
  cat: {
    type: 'numberArray',
    value: [],
    arrayType: '+'
  },
  c_type: {
    type: 'numberArray',
    value: [],
    arrayType: '+'
  },
  p_type: {
    type: 'numberArray',
    value: [],
    arrayType: '+'
  },
  salary: {
    type: 'numberArray',
    value: [],
    arrayType: '+'
  },
  keywords: {
    type: 'string',
    value: ''
  },
  page: {
    type: 'number',
    value: 0
  },
  latlng: {
    type: 'string',
    value: ''
  },
  distance: {
    type: 'number',
    value: 10
  },
  location: {
    type: 'string',
    value: ''
  },
  type: {
    type: 'stringArray',
    value: [],
    arrayType: '[]'
  },
  from: {
    type: 'string',
    value: ''
  },
  sort: {
    type: 'string',
    value: 'pd'
  },
  work_from_home: {
    type: 'numberArray',
    value: [],
    arrayType: '[]'
  },
  employee_sector: {
    type: 'stringArray',
    value: [],
    arrayType: '[]'
  },
  org_type: {
    type: 'stringArray',
    value: [],
    arrayType: '[]'
  },
};

export { sortValues, distanceValues, searchParams }
