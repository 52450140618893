import packageInfo from '../../package.json';

export const environment = {
  production: true,
  baseUrl: '',
  oauthDebug: false,
  scopes: 'agency organisation admin authenticated',
  tokenEndpoint: 'https://admin.myjobscotland.gov.uk/oauth/token',
  profileEndpoint: 'https://admin.myjobscotland.gov.uk/api/v1/user/profile',
  apiUrl: 'https://admin.myjobscotland.gov.uk',
  web1: 'https://admin.myjobscotland.gov.uk',
  client_id: 'b0919895-cb05-4c48-992a-d942c4ea78c8',
  client_secret: 'secret',
  hotjar_tracking_id: '1559429',
  google_analytics: 'UA-138579075-1',
  recaptcha: '6LcW3jsUAAAAALUSrACBAcoxwSzC8TRVFJX38nYf',
  recaptcha_v3: '6LfIm5whAAAAAILjJeIeGcplI6wf5JudkznntlUu',
  is: 'production',
  applyMethod: 'rac',
  lumesse_technical_id: 'PXOFK026203F3VBQB798M8MZY',
  lumesse_apply_host: 'https://apply5.lumessetalentlink.com',
  lumesse_config_key: 'AAAB/gAA-bc0a0e28-e5f3-479c-a924-9fff1d5fb5f8',
  lumesse_client_id: 'MTU510',
  lumesse_scripts_version: '2402201620',
  paypalMethod: 'sdk', // sdk or iframe
  paypal_client_id: 'AYJJ8jJ0ixUUP6vvnjwaqvJSD8R87dStYMJkhxMev8yZs-1bNuiukUWoUafV0VDOaW0kzUBHEvMxZNcj',
  version: packageInfo && packageInfo.hasOwnProperty('version') ? packageInfo.version : '1.5.0',
  resourceUrls: [
    'https://admin.myjobscotland.gov.uk',
    '/api/v1',
    '/api/v1/logs/error'
  ],
  adultCareCategory: 2385,
  edinburghPartnershipId: 7868,
  showCreateJobAlertCTA: true,
};
