import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Resolve } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of, catchError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BannerService } from '@app/modules/home/services/banner.service';
import { Banner } from '@app/models';

@Injectable()
export class BannerResolver implements Resolve<Banner> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private bannerService: BannerService) { }

  resolve() {
    const BANNER_KEY = makeStateKey<Banner>(`banner`);

    if (this.transferState.hasKey(BANNER_KEY)) {
      const banner = this.transferState.get<Banner>(BANNER_KEY, null);
      this.transferState.remove(BANNER_KEY);
      return of(banner);
    }

    return this.bannerService.get()
    .pipe(
      tap(banner => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(BANNER_KEY, banner);
        }

        return of(banner);
      }),
      catchError(error => {
        return of(error);
      })
    );
  }

}
