import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { MjsSearchParams } from '../../models/mjs-search-params.model';
import { MjsSearchResults } from '../../models/mjs-search-results.model';

@Component({
  selector: 'mjs-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchHeaderComponent {
  
  @Input() isMobile: boolean;
  @Input() content: object;
  @Input() organisation: object;
  @Input() menu: object;
  @Input() subtitle: string;
  @Input() jobs: MjsSearchResults;
  @Input() params: MjsSearchParams;
  @Input() loading: boolean;
  @Input() auto: boolean;
  @Input() map: boolean;
  @Input() isBrowser: boolean;
  
  @Output() toggleMap: EventEmitter<any> = new EventEmitter();
  @Output() unlockFacets: EventEmitter<any> = new EventEmitter();

  summary: string;
  filter_summary: string;

  constructor() {
    this.summary = this.filter_summary = 'Searching... ';
  }

  unlock(): void {
    this.unlockFacets.emit();
  }

  switchMap(): void {
    this.toggleMap.emit();
  }

  summaryDisplay(): string {
    this.summary = 'Searching...';

    if (this.jobs) {
      if (this.jobs.total <= 10) {
        this.summary = `<span class="d-none d-sm-inline-block">Showing&nbsp;</span>${(this.jobs.total == 1 ? '1 available job' : this.jobs.total + ' <span class="d-none d-sm-inline-block">available&nbsp;</span>jobs')}`;
      } else {
        this.summary = `<span class="d-none d-sm-inline-block">Showing&nbsp;</span>${((this.jobs.page - 1) * 10 + 1) + ' - ' + Math.min( this.jobs.page * 10, this.jobs.total)} of ${this.jobs.total} <span class="d-none d-sm-inline-block">available&nbsp;</span>jobs`;
      }
    }

    return this.summary;
  }

}
