<div class="footer-widgets" *ngIf="menus">
  <div class="container">
    <div class="row" *ngIf="menus['sections']">
      <h2 class="sr-only">Frequented <span>links</span></h2>

      <div class="col-md-4" *ngFor="let item of menus['sections']">
        <a
          *ngIf="!item.external"
          class="footer-box"
          routerLink="{{item.url}}"
          routerLinkActive="active">
          <span class="box-icon" [innerHTML]="item.icon | safe"></span>
          <span class="box-title">{{item.title}}</span>
        </a>
        <a
          *ngIf="item.external"
          class="footer-box"
          [href]="item.url"
          target="_blank"
          rel="noopener noreferer">
          <span class="box-icon" [innerHTML]="item.icon | safe"></span>
          <span class="box-title">{{item.title}}</span>
          <span class="sr-only"> (Opens in new tab)</span>
        </a>
      </div>
    </div>
  </div>
</div>
<footer class="site-footer" role="contentinfo" *ngIf="menus">
  <mjs-menu
    *ngIf="menus"
    [menu]="menus['legal']"
    [classname]="'legal-menu'">
  </mjs-menu>
  <mjs-menu
    *ngIf="menus && menus['partners']"
    [menu]="menus['partners']"
    [classname]="'awards'">
  </mjs-menu>
  <mjs-menu
    *ngIf="menus"
    [menu]="menus['social']"
    [classname]="'social'">
  </mjs-menu>
  <span class="copyright"><span>&copy;</span> myjobscotland</span>
</footer>
