import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { SharedModule } from '@app/shared/shared.module';
import {
  LocationComponent,
  KeywordsComponent,
  SortComponent,
  CheckboxesComponent,
  JobListingComponent,
  SearchHeaderComponent
} from './components';
import { SearchFormService } from './services/search-form.service';
import { EngageAnalyticsService } from './services/engage-analytics.service';
import { SearchFormResolver } from './services/search-form.resolver';
import { AppleMapComponent } from './components/apple-map/apple-map.component';
import { SalaryPipe } from '@app/shared/pipes/salary.pipe';
import { EllipsisPipe } from '@app/shared/pipes/ellipsis.pipe';
import { FacetService } from './services/facet.service';
import { CreateAlertComponent } from './components/create-alert/create-alert.component';
import { JobSearchService, SearchResolver } from './services';

@NgModule({
  declarations: [
    SearchComponent,
    CheckboxesComponent,
    JobListingComponent,
    SearchHeaderComponent,
    LocationComponent,
    KeywordsComponent,
    SortComponent,
    AppleMapComponent,
    CreateAlertComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SearchRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    OrderModule,
    MatDialogModule,
    MatAutocompleteModule,
    OverlayModule,
    LazyLoadImageModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    CheckboxesComponent,
    KeywordsComponent,
    LocationComponent,
    SearchComponent,
    SortComponent,
    AppleMapComponent
  ],
  providers: [
    SearchFormService,
    SearchFormResolver,
    FacetService,
    EngageAnalyticsService,
    JobSearchService
    // SearchResolver
  ]
})
export class SearchModule { }
