import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  PLATFORM_ID
} from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subscription, throwError, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  MessagesService,
  LumesseUrlConvertService,
  SiteMessageService
} from '@app/services';

@Component({
  selector: 'mjs-site-messages',
  templateUrl: './site-messages.component.html',
  styleUrls: ['./site-messages.component.scss'],
  providers: [LumesseUrlConvertService, SiteMessageService],
  encapsulation: ViewEncapsulation.None
})
export class SiteMessagesComponent implements OnInit, OnDestroy {

  messagesSubscription: Subscription;
  currentMessages: any;
  currentMessagesSubscription: Subscription;

  messages: any[] = [];
  initialMessage: string;
  siteMessages: any[] = [];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private messagesService: MessagesService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: any,
    private toast: ToastrService,
    private lumesseUrl: LumesseUrlConvertService,
    private siteMessageService: SiteMessageService,
    private cookieService: CookieService,
    private oauthService: OAuthService) {
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }

    this.currentMessagesSubscription = this.messagesService
    .currentMessages.pipe(takeUntil(this.onDestroy$)).subscribe(list => {
      this.currentMessages = list;
      this.applyMessage();
    });

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      // Check for any mapped message parameter and display relevant message
      // on client side only.
      if (event instanceof NavigationEnd && isPlatformBrowser(this.platform)) {
        this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
          .subscribe((paramMap: ParamMap) => {
          this.applyMessage();
        });

        // Check for main site message and check user hasn't read it already
        // by checking time of siteMessage cookie.
        this.siteMessageService.get().pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          let list = [];
          let newList = [];
          let cookie = this.cookieService.get(`siteMessages`);

          if (cookie) {
            list = cookie.split('_');
          }

          if (data && data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
              newList.push(data[i].uuid);

              let add = true;
              if (list.length > 0 && list.indexOf(data[i].uuid) > -1) {
                add = false;
              }

              for (let e = 0; e < this.siteMessages.length; e += 1) {
                if (data[i].uuid === this.siteMessages[e].uuid) {
                  add = false;
                }
              }

              if (!add) continue;

              if (data[i].dismiss) {
                this.siteMessages.push(data[i]);
              } else {
                this.siteMessages.unshift(data[i]);
              }
            }
          }

          if (list.length > 0) {
            for (let i = 0; i < list.length; i += 1) {
              if (newList.indexOf(list[i]) < 0) {
                list.splice(i, 1);
                this.cookieService.set('siteMessages', list.join('_'));
              }
            }
          }
        });
      }
    });

    // Fetch matched messages. These messages are shown if a "message" query param
    // exists as a key.
    this.messagesService.get().pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (messages: any) => this.messages = messages,
      error: error => { }
    });
  }

  applyMessage() {
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
                          .subscribe((paramMap: ParamMap) => {
      const params = paramMap.keys;
      let stop = false;

      for (let i = 0; i < params.length; i += 1) {
        let key = params[i];
        if (key === 'message') {
          const param = paramMap.get(key);

          if (param === 'application_already_applied') {
            let id = paramMap.get('id');
            let token = this.oauthService.getAccessToken();
            this.toast.info('You have an incomplete application for this position. If you want to continue you click "continue" below otherwise <a href="/apply/reapply-' + id + '?reapply&token=' + token + '">click here</a>');
          }
          else {
            if (this.currentMessages[param]) {
              this.initialMessage = param;
              this.toast.info(this.currentMessages[param].message);
            }
          }
        }

        if (key === 'nPostingTargetId') {
          this.lumesseUrl.get(paramMap.get(key))
            .pipe(takeUntil(this.onDestroy$)).subscribe((res: any) => {
            if (res.redirect) {
              this.router.navigate([res.redirect]);
            }
          });
        }
      }
    });
  }

  public dismissMessage(index): void {
    let list = [];
    let cookie = this.cookieService.get(`siteMessages`);
    if (cookie) list = cookie.split('_');
    if (list.length > 2) list.shift();
    list.push(this.siteMessages[index].uuid);

    this.cookieService.set(`siteMessages`, list.join('_'));

    if (this.siteMessages[index]) {
      this.siteMessages.splice(index, 1);
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
