<nav class="pager" *ngIf="pages > 1" aria-label="Pagination navigation">
  <a
    class="first"
    [routerLink]="currentPath"
    [queryParams]="{page: 1}"
    ariaCurrentWhenActive="page"
    queryParamsHandling="merge"
    [ngClass]="{ inactive: !values.start.first }">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
      <path d="M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z"></path>
    </svg>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
      <path d="M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z"></path>
    </svg>
    <span class="sr-only">Go to first page</span>
  </a>

  <a
    class="previous"
    [routerLink]="currentPath"
    ariaCurrentWhenActive="page"
    [queryParams]="{page: values.start.back > 1 ? values.start.back : 1}"
    queryParamsHandling="merge"
    [ngClass]="{ inactive: !values.start.back }">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
      <path d="M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z"></path>
    </svg>
    <span class="sr-only">Go to previous page</span>
  </a>

  <ul>
    <li *ngFor="let page of values.middle">
      <a
        [routerLink]="currentPath"
        [queryParams]="{page: page.page}"
        ariaCurrentWhenActive="page"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [attr.aria-label]="'Go to page ' + page.page">
        {{ page.page }}
      </a>
    </li>
  </ul>

  <a class="next"
    [routerLink]="currentPath"
     ariaCurrentWhenActive="page"
    [queryParams]="{page: values.end.next}"
    queryParamsHandling="merge"
    [ngClass]="{ inactive: !values.end.next }">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
      <path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path>
    </svg>
    <span class="sr-only">Go to next page</span>
  </a>

  <a
    class="last"
    [routerLink]="currentPath"
    ariaCurrentWhenActive="page"
    [queryParams]="{page: values.end.last}"
    queryParamsHandling="merge"
    [ngClass]="{ inactive: !values.end.last }">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
      <path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path>
    </svg>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
      <path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path>
    </svg>
    <span class="sr-only">Go to last page</span>
  </a>

</nav>

