import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of, EMPTY } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ContentService } from './content.service';
import { Content } from '@app/models';

@Injectable()
export class ContentResolver implements Resolve<Content> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private router: Router,
    private content: ContentService) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    let url = rstate.url.split('?')[0];
    
    if (url.substring(0, 11) === '/shortlink/') {
      url = url.replace('/shortlink', '');
    }

    let content_key = url.replace('/', '_');
    const CONTENT_KEY = makeStateKey<Content>(`content-${content_key || 'home'}`);

    if (this.transferState.hasKey(CONTENT_KEY)) {
      const content = this.transferState.get<Content>(CONTENT_KEY, null);
      this.transferState.remove(CONTENT_KEY);
      return of(content);
    }
    
    return this.content.getPage(url)
    .pipe(
      tap(content => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(CONTENT_KEY, content);
        }
        
        return of(content);
      }),
      catchError(error => {
        // Redirect to 404 component which serves a 404 status code to the server.
        this.router.navigate(['/404'], { skipLocationChange: true });
        return EMPTY;
      })
    );
  }

}
