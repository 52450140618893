<ng-container *ngIf="list">
  <ng-container *ngFor="let i of Array(Math.ceil(list.length / 3)); let _i = index">
    <section
      class="promotions"
      [attr.data-count]="list.slice((_i * 3), ((_i * 3) + 3)).length">

      <div
        class="promotion"
        *ngFor="let e of list.slice((_i * 3), ((_i * 3) + 3))">
        <a *ngIf="e.external" [href]="e.url">
          <figure
            defaultImage="/assets/img/defaults/default-header.svg"
            [errorImage]="e.image.styles.header.url"
            [lazyLoad]="webp.defaultImage(e.image.styles.header.url)">
            <img [lazyLoad]="e.image.styles.thumb.url" [alt]="" />
          </figure>
          <div class="container">
            <p class="h2">{{ e.title }}</p>
            <p *ngIf="e.subtitle">{{ e.subtitle }}</p>
          </div>
        </a>

        <a *ngIf="!e.external" [routerLink]="e.path" [queryParams]="e.params">
          <figure
            defaultImage="/assets/img/defaults/default-header.svg"
            [errorImage]="e.image.styles.header.url"
            [lazyLoad]="webp.defaultImage(e.image.styles.header.url)">
            <img [lazyLoad]="e.image.styles.thumb.url" [alt]="" />
          </figure>
          <div class="container">
            <p class="h2">{{ e.title }}</p>
            <p *ngIf="e.subtitle">{{ e.subtitle }}</p>
          </div>
        </a>

      </div>

    </section>
  </ng-container>
</ng-container>
