// Angular
import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { RouterModule } from '@angular/router';
// Libraries
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastContainerModule, ToastNoAnimationModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaFormsModule,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module
} from 'ng-recaptcha';
import { Angulartics2Module } from 'angulartics2';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { QuillModule } from 'ngx-quill';
import { LazyLoadImageModule } from 'ng-lazyload-image';
// Modules
import { HomeModule } from '@app/modules/home/home.module';
import { SharedModule } from '@app/shared/shared.module';
// Components
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { HeaderComponent } from '@app/components/header/header.component';
import { FooterComponent } from '@app/components/footer/footer.component';
import { MjsToastComponent } from '@shared/components/mjs-toast/mjs-toast.component';
// Services
import { SearchFormService } from '@app/modules/search/services/search-form.service';
import { BannerService } from '@app/modules/home/services/banner.service';
import {
  MessagesService,
  RecruiterService,
  ProfileService,
  ContentService,
  FormService,
  ContextTokenService,
  JobAdvertService,
  SitemapService,
  SitemapResolver,
  ContentResolver,
  BannerResolver,
  RecruiterResolver,
  ContextTokenResolver,
  JobAdvertResolver,
  WebpService
} from '@app/services';
import { OrganisationFiltersResolver } from '@app/modules/organisations/organisation-filters.resolver';
import { OrganisationsResolver } from '@app/modules/organisations/organisations.resolver';
import { SearchFormResolver } from '@app/modules/search/services/search-form.resolver';
// Guards
import {
  LoggedInGuard,
  LoggedOutGuard,
  RecruiterGuard
} from '@app/guards';
// Interceptors
import { DefaultOAuth2Interceptor } from '@app/interceptor/oauth2.interceptor';
// Environment
import { environment } from '../environments/environment';
import { SearchResolver } from './modules/search/services';

if (environment.production) {
  enableProdMode();
}

export function storageFactory() : OAuthStorage {
  return (typeof window !== "undefined") ? window.localStorage : null;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MjsToastComponent
  ],
  imports: [
    // Angular
    TransferHttpCacheModule,
    BrowserModule.withServerTransition({ appId: 'myjobscotland' }),
    BrowserTransferStateModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    // Libraries
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.TRACE,
      serverLoggingUrl: `${environment.apiUrl}/api/v1/logs/error`,
      disableConsoleLogging: true
    }),
    LoadingBarRouterModule,
    Angulartics2Module.forRoot(),
    ToastNoAnimationModule.forRoot({
      maxOpened: 4,
      autoDismiss: false,
      iconClasses: {},
      newestOnTop: true,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: false,
      positionClass: 'toast-top-center',
      enableHtml: true,
      disableTimeOut: true,
      progressBar: false,
      closeButton: true,
      tapToDismiss: false,
      timeOut: 6500,
      toastComponent: MjsToastComponent
    }),
    ToastContainerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    NgxWebstorageModule.forRoot(),
    MatDialogModule,
    MatMenuModule,
    MatAutocompleteModule,
    OverlayModule,
    A11yModule,
    QuillModule.forRoot({
      suppressGlobalRegisterWarning: true
    }),
    LazyLoadImageModule,
    // App modules
    SharedModule,
    RouterModule,
    AppRoutingModule,
    SharedModule,
    HomeModule
  ],
  exports: [
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [{
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha
      } as RecaptchaSettings,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuth2Interceptor,
      multi: true
    }, {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha_v3
    },
    Title,
    CookieService,
    LazyLoadImageModule,
    // Resolvers
    OrganisationFiltersResolver,
    OrganisationsResolver,
    BannerResolver,
    SearchFormResolver,
    RecruiterResolver,
    SitemapResolver,
    ContentResolver,
    ContextTokenResolver,
    JobAdvertResolver,
    // Services
    MessagesService,
    ContentService,
    BannerService,
    SearchFormService,
    FormService,
    RecruiterService,
    ProfileService,
    SitemapService,
    ContextTokenService,
    JobAdvertService,
    WebpService,
    // Guards
    LoggedInGuard,
    LoggedOutGuard,
    RecruiterGuard,
    CookieService,
    SearchResolver
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
