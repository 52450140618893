import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './search.component';
import { SearchResolver } from './services';
import { SearchFormResolver } from './services/search-form.resolver';

const SEARCH_ROUTES: Routes = [{
  path: '',
  component: SearchComponent,
  resolve: {
    filters: SearchFormResolver,
    // searchResults: SearchResolver
  },
  // runGuardsAndResolvers: 'paramsOrQueryParamsChange'
}];

@NgModule({
  imports: [RouterModule.forChild(SEARCH_ROUTES)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
