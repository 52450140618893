import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FacetService {

  private subject = new Subject<any>();

  constructor() { }

  changeValue(facets) {
    this.subject.next(facets);
  }

  getFacets(): Observable<any> {
    return this.subject.asObservable();
  }

}
