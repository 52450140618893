import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebpService } from '@app/services';

@Component({
  selector: 'mjs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  
  content: any;
  banner: any;
  auto: boolean;
  distance: number;
  location: string;
  latlng: string;
  keywords: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public webp: WebpService) {
    this.auto = false;
    this.distance = 10;
    this.keywords = '';
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
      this.banner = res['banner'];
    });
  }

  public search(): void {
    this.router.navigate(['/search'], {
      queryParams: {
        keywords: this.keywords,
        latlng: this.latlng,
        location: this.location,
        distance: 10
      }
    });
  }

  public updateLocation(event: any): void {
    this.latlng = event.latlng;
    this.location = event.location;
  }

  public updateKeywords(event: any): void {
    this.keywords = event;
  }

}
