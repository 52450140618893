/**
 * Convert form values to URL search params.
 */

import { Params } from "@angular/router";
import { MjsSearchParams } from "../models/mjs-search-params.model";
import { sortValues, distanceValues } from '../search-params';

const paramsToSearchParams = (params: MjsSearchParams, defaultParams: any): Params => {
  const output = {}

  for (let key in params) {
    if (params[key].value.length < 1) {
      continue;
    }

    switch (params[key].type) {
      case 'numberArray':
      case 'stringArray':
        output[key] = params[key].value.filter((value, position, arr) => {
          return arr.indexOf(value) === position;
        }).join(',');
        break;

      case 'number':
        switch (key) {
          case 'distance':
            if (distanceValues.indexOf(params[key].value) > -1) {
              output[key] = params[key].value;
            }
            break;

          default: output[key] = params[key].value;
        }
        break;

      case 'string':
        switch (key) {
          case 'changing': break;
          case 'sort':
            if (sortValues.hasOwnProperty(params[key].value)) {
              output[key] = params[key].value;
            }
            break;
          
          default: output[key] = params[key].value;
        }
        break;
    }
  }

  return output;
}

export { paramsToSearchParams }
