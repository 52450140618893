<ul *ngIf="jobs && jobs.length > 0" class="featured-jobs-widget list-unstyled">
  <ng-container *ngFor="let item of jobs; let i = index">
  <li *ngIf="i < 3">
    <a [routerLink]="item.path" class="d-block">
      <p class="h5 font-weight-bold" [innerHtml]="item.title"></p>
      <p>{{ item | salary }}</p>
    </a>
  </li>
  </ng-container>
</ul>
