import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { FavouritesService } from '@app/modules/user/services/favourites.service';
import { WebpService } from '@app/services';
import { JobPreview } from '@app/models';

@Component({
  selector: 'mjs-job-listing',
  templateUrl: './job-listing.component.html',
  styleUrls: ['./job-listing.component.scss'],
  providers: [FavouritesService],
  encapsulation: ViewEncapsulation.None
})
export class JobListingComponent {
  
  @Input() advert: JobPreview;

  constructor(private oauthService: OAuthService,
    private toast: ToastrService,
    private favourites: FavouritesService,
    public webp: WebpService) { }

  favourite(): void {
    const state = this.advert.user_like;

    if (this.oauthService.hasValidAccessToken()) {
      this.favourites.patch(this.advert.id).subscribe({
        next: (data: any) => {
          this.advert.user_like = state == '1' ? '0' : '1';
          this.toast.success(data.message)
        },
        error: err => {
          this.signInMessage();
          catchError(err);
        }
      });
    } else {
      this.signInMessage();
    }
  }

  signInMessage() {
    this.toast.error('You need to sign in to add favourites. <a href="/user/login">Click here</a> to sign in.');
  }

  ellipsis = (input: string): string => {
    if (input.length >= 36) {
      return `${input.substring(0, 36)}...`;
    }

    return input;
  }

}
