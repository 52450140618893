import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MjsSearchResults } from '@modules/search/models/mjs-search-results.model';
import { environment } from '@env/environment';

@Injectable()
export class JobSearchService {

  constructor(private http: HttpClient) {}

  get(params: HttpParams): Observable<MjsSearchResults> {
    return this.http.get<MjsSearchResults>(`${environment.apiUrl}/api/v1/search`, { params });
  }

}
