<div class="mjs-home-banner">
  <div class="banner-content">
    <figure *ngIf="banner && banner.img">
      <img [errorImage]="banner.img.large"
        [lazyLoad]="webp.defaultImage(banner.img.large)" alt="">
    </figure>
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 *ngIf="banner && banner.title">{{ banner.title }}</h1>
          <p *ngIf="banner && banner.subtitle">{{ banner.subtitle }}</p>
          <a [routerLink]="'/search'" class="jobs-available" target="_self">Search<span *ngIf="banner && banner.count">{{ banner.count }}</span>jobs</a>
        </div>
      </div>
    </div>
    <p class="m-0 banner-credit" *ngIf="banner && banner.image_credit"
      [innerHtml]="banner.image_credit"></p>
    <div class="home-search-form">
      <div class="container">
        <div class="row">
          <div class="col d-flex">
            <mjs-keywords
              style="flex:1"
              [keywords]="keywords"
              [auto]="auto"
              [searchRoute]="'/search'"
              (valueChange)="updateKeywords($event)">
            </mjs-keywords>
            <mjs-location
              style="flex:1"
              [latlng]="latlng"
              [location]="location"
              [distance]="distance ? distance.toString() : ''"
              [auto]="auto"
              (valueChange)="updateLocation($event)">
            </mjs-location>
            <button
              type="button"
              class="btn btn-primary"
              (click)="search()">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mjs-promotions
  *ngIf="content && content.views"
  [list]="content.views.promotions__list"
></mjs-promotions>
<mjs-featured-jobs
  *ngIf="content && content.views"
  [list]="content.views.featured_jobs__list"
  [latest]="content.views.featured_jobs__latest"
></mjs-featured-jobs>
<mjs-category-list></mjs-category-list>
