import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {

  constructor(private http: HttpClient) {}

  get(params: any) {
    const query = [];
    let httpParams = new HttpParams().set('_format', 'json');

    for (let key in params) {
      switch (key) {
        case 'type':
          if (params[key] && params[key].length > 0) {
            let ids = params[key].split(',');

            for (let i = 0; i < ids.length; i += 1) {
              query.push('type[]=' + ids[i])
              httpParams = httpParams.append(`type[]`, ids[i]);
            }
          }
          break;

        case 'sort':
          let parts = params[key].split('_');

          if (parts.length !== 2) {
            httpParams = httpParams.append('sort_by', 'count');
            httpParams = httpParams.append('sort_order', 'DESC');
          }
          else {
            httpParams = httpParams.append('sort_by', parts[0]);
            httpParams = httpParams.append('sort_order', parts[1].toUpperCase());
          }
          break;

        case 'query':
          if (params[key]) {
            httpParams = httpParams.append('name', params[key]);
          }
          break;

        case 'page':
          let val = parseInt(params[key], 10);
          if (val > 1) {
            val = val - 1;
            httpParams = httpParams.append('page', val.toString());
          }
          break;

        default:
          if (params[key]) httpParams = httpParams.append(key, params[key]);
      }
    }

    return this.http.get(`${environment.apiUrl}/api/v1/search-organisations`, {
      params: httpParams
    })
  }
}
