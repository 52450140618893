import { Component, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: 'mjs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() username: string;
  @Input() password: string;
  @Input() submit: Function;

  form: FormGroup;
  submitted: boolean;
  redirectParam: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute) {
    this.form = new FormGroup({
      username: new FormControl<String|null>('', [Validators.required, Validators.email]),
      password: new FormControl<String|null>('', Validators.required),
    });
    this.submitted = false;
    this.redirectParam = '';
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
        this.redirectParam = paramMap.get('redirect');
      });

  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.submit(this.form.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
