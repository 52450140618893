import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { OverlayModule } from '@angular/cdk/overlay';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { QuillModule } from 'ngx-quill';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { SocialShareComponent } from './components/social-share/social-share.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { FeaturedJobsWidgetComponent } from './components/featured-jobs-widget/featured-jobs-widget.component';
import { PostNavigationComponent } from './components/post-navigation/post-navigation.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CouncilMapComponent } from './components/council-map/council-map.component';
import { FluidMenuComponent } from './components/fluid-menu/fluid-menu.component';
import { LocationsService } from './services/locations.service';
import { SalaryPipe } from './pipes/salary.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { SidebarLinksComponent } from './components/sidebar-links/sidebar-links.component';

import {
  LoginComponent,
  PromotionsComponent,
  FeaturedJobsComponent,
  CategoryListComponent,
  AdvertComponent,
  MenuComponent,
  PageHeaderComponent,
  NotFoundComponent,
  SafePipe,
  KeyValuePipe,
  PagerComponent,
  EntityFormComponent,
  LoaderComponent,
  SiteMessagesComponent
} from './';

import {
  TextComponent,
  NumberComponent,
  DateComponent,
  CheckboxComponent,
  SelectComponent,
  RadioComponent,
  TextareaComponent,
  ImageComponent,
  UrlComponent,
  PasswordComponent,
  EmailComponent,
  CheckboxesComponent,
  FileComponent
} from './components/entity-form';

const imports = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  OverlayModule,
  // MatPasswordStrengthModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMenuModule,
  QuillModule,
  LazyLoadImageModule
];

const declarations = [
  LoginComponent,
  PageHeaderComponent,
  NotFoundComponent,
  AdvertComponent,
  MenuComponent,
  SafePipe,
  KeyValuePipe,
  CategoryListComponent,
  FeaturedJobsComponent,
  PromotionsComponent,
  PagerComponent,
  EntityFormComponent,
  TextComponent,
  NumberComponent,
  DateComponent,
  CheckboxComponent,
  SelectComponent,
  RadioComponent,
  TextareaComponent,
  LoaderComponent,
  SocialShareComponent,
  ReadMoreComponent,
  ImageComponent,
  UrlComponent,
  PasswordComponent,
  EmailComponent,
  CheckboxesComponent,
  FileComponent,
  SiteMessagesComponent,
  FluidMenuComponent,
  SalaryPipe,
  EllipsisPipe,
  FeaturedJobsWidgetComponent,
  PostNavigationComponent,
  BreadcrumbsComponent,
  CouncilMapComponent,
  SidebarLinksComponent 
];

const exportlist = [
  LoginComponent,
  PageHeaderComponent,
  NotFoundComponent,
  AdvertComponent,
  MenuComponent,
  SafePipe,
  SalaryPipe,
  CategoryListComponent,
  FeaturedJobsComponent,
  PromotionsComponent,
  PagerComponent,
  EntityFormComponent,
  LoaderComponent,
  SocialShareComponent,
  ReadMoreComponent,
  EllipsisPipe,
  SiteMessagesComponent,
  FeaturedJobsWidgetComponent,
  PostNavigationComponent,
  BreadcrumbsComponent,
  CouncilMapComponent,
  SidebarLinksComponent 
];

@NgModule({
  declarations: declarations,
  imports: imports,
  exports: exportlist,
  providers: [
    LocationsService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    }
  ]
})
export class SharedModule { }
