<section class="mjs-featured-jobs" *ngIf="list && list.length > 0">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="heading spec">Featured <span>Jobs</span></h2>
      </div>
      <div class="col-md-12">
        <ul class="job-list">
          <li *ngFor="let item of list; let i - index">
            <a
              class="search-result"
              [routerLink]="item.path || null"
              [queryParams]="{ featured: 'true' }">
              <div>
                <h3 [innerHtml]="item.title"></h3>
                <p [innerHtml]="item.address"></p>
                <img
                  defaultImage="/assets/img/defaults/default-icon.svg"
                  [lazyLoad]="webp.defaultImage(item.org_logo)"
                  [errorImage]="item.org_logo"
                  [alt]="item.org_name"
                  class="lazyload"
                />
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

