import { Injectable, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { WebpService } from '@app/services';

@Injectable()
export class SeoService {

  addedNames = [];
  addedProperties = [];

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private _renderer: Renderer2,
    private _meta: Meta,
    private _title: Title,
    @Inject(DOCUMENT) private _doc: Document,
    private webp: WebpService) {
    _meta.addTag({
      name: `viewport`,
      content: `width=device-width,initial-scale=1`
    });
  }

  addTags(meta: any) {
    if (!meta) {
      return null;
    }

    const head = this._doc.getElementsByTagName('head');
    this.removeTags();

    for (const key in meta.meta) {
      switch (key) {
        case 'title':
          this._title.setTitle(meta.meta.title);
          this.addTag('name', 'twitter:title', meta.meta[key]);
          this.addTag('property', 'og:title', meta.meta[key]);
          if (isPlatformBrowser(this.platformId) && meta.meta[key]) {
            this._renderer.selectRootElement('#announce-title', true).innerHTML = meta.meta[key];
          }
          break;
        case 'description':
          this.addTag('name', 'description', meta.meta[key]);
          this.addTag('name', 'twitter:description', meta.meta[key]);
          this.addTag('property', 'og:description', meta.meta[key]);
          break;
        case 'canonical_url':
          this.addTag('name', 'canonical', meta.meta[key]);
          this.addTag('name', 'twitter:url', meta.meta[key]);
          this.addTag('property', 'og:url', meta.meta[key]);
          break;
        case 'robots':
          this.addTag('name', 'robots', meta.meta[key]);
          break;
        case 'schema':
          const schemaElements = this._doc.getElementsByClassName('schema');
          if (schemaElements) {
            for (let i = 0; i < schemaElements.length; i += 1) {
              this._renderer.removeChild(head[0], schemaElements[i]);
            }
          }
          const schema = this._renderer.createElement('script');
          this._renderer.addClass(schema, 'schema');
          this._renderer.setAttribute(schema, 'type', 'application/ld+json');
          schema.innerHTML = JSON.stringify(meta.meta[key], null, 2).replace(/\//g, '\\/');
          this._renderer.appendChild(head[0], schema);
          break;
        case 'image_src':
          this.addTag('name', 'twitter:image', meta.meta[key]);
          this.addTag('property', 'og:image', meta.meta[key]);

          // Add preload link for banner image if it exists.
          const bannerLink = this._doc.getElementById('banner-preload');

          if (bannerLink) {
            this._renderer.removeChild(head[0], bannerLink);
          }

          const link = this._renderer.createElement('link');
          this._renderer.setAttribute(link, 'rel', 'preload prefetch');
          this._renderer.setAttribute(link, 'id', 'banner-preload');
          this._renderer.setAttribute(link, 'as', 'image');
          // this._renderer.setAttribute(link, 'crossOrigin', 'anonymous');
          this._renderer.setAttribute(link, 'href', this.webp.defaultImage(meta.meta[key]));
          this._renderer.appendChild(head[0], link);
          break;
        default:
      }
    }
  }

  addTag(type, tag, content) {
    switch (type) {
      case 'name':
        this._meta.updateTag({ name: tag, content: content });
        this.addedNames.push(`name='${tag}'`);
        break;
      case 'property':
        this._meta.updateTag({ property: tag, content: content });
        this.addedProperties.push(`property='${tag}'`);
        break;
    }
  }

  removeTags() {
    for (let i = 0; i < this.addedNames.length; i += 1) {
      this._meta.removeTag(this.addedNames[i]);
    }

    for (let i = 0; i < this.addedProperties.length; i += 1) {
      this._meta.removeTag(this.addedProperties[i]);
    }
  }

}
