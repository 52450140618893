<div  mat-dialog-title class="shadow-sm mb-0">
  <h2 class="mjs-heading mb-0">{{ alertCreated ? 'Success' : 'Create your alert' }}</h2>
</div>
<div mat-dialog-content class="pt-3">
  <ng-container *ngIf="alertCreated">
    <div class="alert alert-success" role="alert">
      <p class="m-0"><strong>Your alert has been created. You will receive emails when jobs matching your selected criteria are found.</strong></p>
    </div>
  </ng-container>
  <ng-container *ngIf="!alertCreated">
    <div class="alert alert-danger" role="alert" *ngIf="!loading && error">
      <p class="m-0"><strong [innerHtml]="error"></strong></p>
    </div>
    <mjs-loader *ngIf="loading"></mjs-loader>
    <form [formGroup]="formGroup" (submit)="submit()" [hidden]="loading">
      <div class="form-group mb-2" *ngIf="!data.user">
        <label for="email" class="font-weight-bold pb-1">Your email address</label>
        <input type="email"
          formControlName="email"
          id="email"
          [ngClass]="{
            'is-invalid': submitted && f['email'].errors
          }"
          class="form-control">
        <div *ngIf="submitted && f['email'].errors" 
          class="invalid-feedback"
          [ngClass]="{
            'd-block': submitted && f['email'].errors
          }">
          <p class="m-0" *ngIf="f['email'].errors['required']">Your email address is required</p>
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="name" class="font-weight-bold pb-1">Saved search name</label>
        <input type="name"
          formControlName="name"
          id="name"
          [ngClass]="{
            'is-invalid': submitted && f['name'].errors
          }"
          class="form-control">
        <div *ngIf="submitted && f['name'].errors" 
          class="invalid-feedback"
          [ngClass]="{
            'd-block': submitted && f['name'].errors
          }">
          <p class="m-0" *ngIf="f['name'].errors['required']">Your must provide a name for the alert</p>
        </div>
      </div>
      <div class="form-group">
        <label for="daily" class="font-weight-bold mb-1">How often should we send emails?</label>
        <div class="position-relative d-flex">
          <div class="custom-control form-check custom-radio mb-1 mr-3">
            <input type="radio"
              formControlName="send"
              value="1"
              class="custom-control-input"
              id="daily"
              [ngClass]="{
                'is-invalid': submitted && f['send'].errors
              }">
            <label class="custom-control-label" for="daily">
              Daily
            </label>
          </div>
          <div class="custom-control form-check custom-radio">
            <input type="radio"
              formControlName="send"
              class="custom-control-input"
              id="weekly"
              value="7"
              [ngClass]="{
                'is-invalid': submitted && f['send'].errors
              }">
            <label class="custom-control-label" for="weekly">
              Weekly
            </label>
          </div>
        </div>
        <div *ngIf="submitted && f['send'].errors"
          class="invalid-feedback"
          [ngClass]="{
            'd-block': submitted && f['send'].errors
          }">
          <p class="m-0" *ngIf="f['send'].errors['required']">You must choose how often you want to receive alert emails.</p>
        </div>
      </div>
      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" 
            class="custom-control-input" 
            id="consented"
            formControlName="consented"
            [ngClass]="{
              'is-invalid': submitted && f['consented'].errors
            }">
          <label class="custom-control-label" for="consented">Consent to receive job alert emails from myjobscotland</label>
        </div>
        <div *ngIf="submitted && f['consented'].errors" 
          class="invalid-feedback"
          [ngClass]="{
            'd-block': submitted && f['consented'].errors
          }">
          <p class="m-0" *ngIf="f['consented'].errors['required']">You must consent to receiving emails from myjobscotland.</p>
        </div>
      </div>
    </form>
  </ng-container>
</div>
<div mat-dialog-actions class="d-flex shadow border-top" [ngClass]="{
  'justify-content-between': !alertCreated,
  'justify-content-center': alertCreated  
}">
  <button type="button" 
    class="btn btn-secondary" 
    mat-button 
    mat-dialog-close>
    {{ alertCreated ? 'Close' : 'Cancel' }}
  </button>
  <button *ngIf="!alertCreated"
    class="btn btn-primary" 
    (click)="submit()">
    Create alert
  </button>
</div>
