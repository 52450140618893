import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { MjsSearchFilter } from '@modules/search/models/mjs-search-filters.model';

@Injectable()
export class SearchFormService {

  constructor(private http: HttpClient) { }

  getForm(): Observable<{ [index: string]: MjsSearchFilter }> {
    return this.http.get<{ [index: string]: MjsSearchFilter }>(`${environment.apiUrl}/api/v1/filters?_format=json`);
  }

}
