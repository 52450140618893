import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { SearchesService } from '@app/modules/user/services';
import { MjsSearchParams } from '@modules/search/models/mjs-search-params.model';
import { prepareSavedSearchParams } from '@modules/search/helpers';

export interface CreateAlertDialogData {
  user: boolean;
  queryParams: MjsSearchParams;
  defaultParams: any;
  saved_search?: any;
}

@Component({
  selector: 'mjs-create-alert',
  templateUrl: './create-alert.component.html',
  styleUrls: ['./create-alert.component.scss'],
  providers: [SearchesService],
  encapsulation: ViewEncapsulation.None
})
export class CreateAlertComponent implements OnInit {

  formGroup: FormGroup;
  loading: boolean;
  submitted: boolean;
  error: string;
  alertCreated: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CreateAlertDialogData,
    public dialogRef: MatDialogRef<CreateAlertComponent>,
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service,
    private searchesService: SearchesService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl<String>('My Saved Search', [Validators.required]),
      send: new FormControl<'1' | '7'>('1', [Validators.required]),
      consented: new FormControl<Boolean>(null, [Validators.required])
    });

    if (!this.data.user) {
      this.formGroup.addControl('email', new FormControl<String|null>('', [Validators.required]));
    }

    this.formGroup.valueChanges.subscribe(res => {
      this.error = '';
    });
  }

  get f() { return this.formGroup['controls'] }

  submit(): void {
    this.submitted = true;

    if (this.formGroup.status == 'INVALID') {
      return;
    }
    
    const { name, send, consented } = this.formGroup.value;

    const data = {
      name,
      send,
      consented,
      query: prepareSavedSearchParams(this.data.queryParams, this.data.defaultParams)
    }

    this.loading = true;

    if (!this.data.user) {
      data['email'] = this.formGroup.value.email;

      this.recaptchaV3Service.execute('create_alert')
        .subscribe({
          next: (token) => {
            data['recaptcha'] = token;
            this.postSavedSearch(data);
          },
          error: err => {
            this.error = 'We were unable to create your job alert. Please sign in and try again or contact us for support.';
            this.loading = true;
          }
        });
    } else {
      this.postSavedSearch(data);
    }
  }

  postSavedSearch(data) {
    this.searchesService.post(data).subscribe({
      next: (res) => {
        this.loading = false;
        this.alertCreated = true;
      },
      error: res => {
        this.error = res?.error?.message ?? 'We were unable to create your job alert. Please sign in and try again or contact us for support.';
        this.loading = false;
      }
    });
  }

}
