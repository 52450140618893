import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { OrganisationFiltersResolver } from '@app/modules/organisations/organisation-filters.resolver';
import { OrganisationsResolver } from '@app/modules/organisations/organisations.resolver';
import { SearchFormResolver } from '@app/modules/search/services';
import { HomeComponent } from '@app/modules/home/home.component';

import {
  BannerResolver,
  ContentResolver,
  SitemapResolver,
  ContextTokenResolver,
  JobAdvertResolver
} from '@app/services';

const APP_ROUTES: Routes = [{
  // HOME
  path: '',
  pathMatch: 'full',
  component: HomeComponent,
  resolve: { content: ContentResolver, banner: BannerResolver }
},{
  // SITEMAP
  path: 'site-map',
  pathMatch: 'full',
  loadChildren: () => import('./modules/sitemap/sitemap.module').then(m => m.SitemapModule),
  resolve: { content: ContentResolver, sitemap: SitemapResolver }
},{
  // SEARCH
  path: 'search',
  pathMatch: 'full',
  loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
  resolve: { content: ContentResolver }
},{
  // USER
  path: 'user',
  loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
},{
  // RECRUITER
  path: 'my-organisations',
  loadChildren: () => import('./modules/recruiter/recruiter.module').then(m => m.RecruiterModule),
},{
  // ORGANISATIONS
  path: 'adult-care',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
  }
}, {
  path: 'councils',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
  }
}, {
  path: 'education',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
  }
}, {
  path: 'childcare',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
  }
}, {
  path: 'emergency-services',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
  }
}, {
  path: 'home-carers',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
  }
}, {
  path: 'partnerships',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
  }
}, {
  path: 'organisations',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver
  }
},{
  path: 'search-organisations',
  loadChildren: () => import('./modules/organisations/organisations.module').then(m => m.OrganisationsModule),
  resolve: {
    content: ContentResolver,
    filters: OrganisationFiltersResolver,
    results: OrganisationsResolver
  }
},{
  // RESPONSIVE APPLICATION COMPONENT
  path: 'rac',
  loadChildren: () => import('./modules/rac/rac.module').then(m => m.RacModule),
  resolve: {
    content: ContentResolver,
    contextToken: ContextTokenResolver,
    job: JobAdvertResolver
  }
}, {
  // SEARCH JOBS
  path: ':type/:organisation/jobs',
  loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
  resolve: { content: ContentResolver }
},{
  path: ':type/:org/:suborg/jobs',
  loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
  resolve: { content: ContentResolver }
},{
  path: ':type/:org/:suborg/:subsuborg/jobs',
  loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
  resolve: { content: ContentResolver }
},{
  path: 'categories',
  loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule),
  resolve: { content: ContentResolver },
  runGuardsAndResolvers: 'always'
},{
  path: 'career-hub',
  loadChildren: () => import('./modules/career-hub/career-hub.module').then(m => m.CareerHubModule),
  //resolve: { content: ContentResolver },
  runGuardsAndResolvers: 'always'
},{
  path: 'teaching-scotland',
  loadChildren: () => import('./modules/education-hub/education-hub.module').then(m => m.EducationHubModule),
},{
  path: '404',
  loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule),
  resolve: { content: ContentResolver }
},{
  path: 'offline',
  loadChildren: () => import('./modules/offline/offline.module').then(m => m.OfflineModule),
},{
  path: ':orgType/:org/jobs/:job',
  loadChildren: () => import('./modules/job/job.module').then(m => m.JobModule),
  resolve: { content: ContentResolver }
},{
  path: ':orgType/:org/:subOrg/jobs/:job',
  loadChildren: () => import('./modules/job/job.module').then(m => m.JobModule),
  resolve: { content: ContentResolver }
},{
  path: 'shortlink/:job',
  loadChildren: () => import('./modules/job/job.module').then(m => m.JobModule),
  resolve: { content: ContentResolver }
},{
  path: '**',
  loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule),
  resolve: { content: ContentResolver, filters: SearchFormResolver }
}];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, -100],
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
